:root {
    --shadow-icon: rgba(73, 73, 73, 0.439);
    --drop-shadow: 2px 2px 1px;
}

.NSPalert-container {
    background: white;
    width: 100%;
    -webkit-box-shadow: 0px 8px 9px -3px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 8px 9px -3px rgba(0, 0, 0, 0.3);
}

.NSPalert-container.fill-container {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.NSP-alertLink {
    border: none;
    background: none;
    color: blue;
    font-weight: bold;
}

.NSP-alert {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 1px solid #dee2e6;
}

.fill-alert {
    border: none !important;
    padding: 0;
    border-radius: 15px;
    background: white;
    width: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: bold;
    margin-bottom: .5rem;
}


.alert-icon {
    position: relative;
    width: 45px;
    height: 45px;
    margin-right: 10px;
}

.alert-icon img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.inverse {
    background-color: var(--bs-tertiary) !important;
}

.alert-icon.inverse img {
    -moz-filter: brightness(0%) invert(100%) drop-shadow(var(--drop-shadow) var(--shadow-icon));
    -webkit-filter: brightness(0%) invert(100%) drop-shadow(var(--drop-shadow) var(--shadow-icon));
    filter: brightness(0%) invert(100%) drop-shadow(var(--drop-shadow) var(--shadow-icon));
}

.fill-alert .inverse {
    border-radius: 10px;
}

.NSP-alert .readed {
    opacity: .5;
}

.NSP-alert .readed img {
    filter: brightness(0) saturate(100%) invert(14%) sepia(98%) saturate(4080%) hue-rotate(236deg) brightness(81%) contrast(119%);
}