:root {
    --livechat-color-disabled: #dee2e6;
    --livechat-header-bg: #0524DD;
    --livechat-section-bg: #F5F6FB;
    --livechat-contacts-bg: #d4edfc;
    --livechat-header-bg2: #FF6900;
    --livechat-btn: 50px;
    --livechat-header-height: 86px;

    --livechat-index: 10;
    --livechat-position: 15px;
    --livechat-height: 85vh;
    --livechat-width: 40vw;

    --livechat-card-right: 15px;

    --livechat-body-height: 330px;
    --livechat-body-radius: 15px;

    --livechat-line-height: 15px;
    --livechat-font-size: 13px;
    --livechat-max-textarea: calc((var(--livechat-line-height) + var(--livechat-font-size))/2)
}

.btn-liveChat {
    position: fixed;
    bottom: var(--livechat-position);
    right: var(--livechat-position);
    width: var(--livechat-btn);
    height: var(--livechat-btn);
    border: 4px solid var(--bs-white);
    border-radius: 50%;
    background: #ffffff90;
    color: var(--bs-primary);
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1);
    z-index: var(--livechat-index);
    padding: 0;
}

.btn-liveChat.products {
    position: fixed;
    bottom: calc(var(--livechat-position)*2 + var(--livechat-btn));
    right: var(--livechat-position);
    width: var(--livechat-btn);
    height: var(--livechat-btn);
    border: 4px solid white;
    border-radius: 50%;
    background: var(--livechat-header-bg);
    color: white;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1);
    z-index: var(--livechat-index);
}

.chatIntera {
    display: flex !important;
    align-items: flex-end;
    position: fixed;
    bottom: calc(var(--livechat-btn) + var(--livechat-position) + 16px);
    right: var(--livechat-card-right);
    z-index: 9999;
}

.card-liveChat {
    border-radius: var(--livechat-body-radius);
    /* max-height: var(--livechat-height); */
    width: var(--livechat-width);
    background: white;
    color: var(--bs-black);
    font-family: 'Montserrat', sans-serif !important;
    z-index: var(--livechat-index);
}

.chatIntera .startConversation {
    right: calc(var(--livechat-btn) + var(--livechat-position) + 1rem);
}

.card-liveChat>header {
    border-top-left-radius: var(--livechat-body-radius);
    border-top-right-radius: var(--livechat-body-radius);
    display: flex;
    text-align: center;
    justify-content: left;
    color: white;
    height: var(--livechat-header-height);
}

.headerLiveChat {
    width: 100%;
}

.card-liveChat .headerLiveChat .client-header, .card-liveChat .headerLiveChat .customerCare-header {
    padding: 0.5em;
    width: 100%;
    min-height: 96px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.card-liveChat .headerLiveChat .client-header>*,
.card-liveChat .headerLiveChat .customerCare-header>* {
    text-align: left !important;
}

.card-liveChat .headerLiveChat .customerCare-header {
    background-color: var(--livechat-header-bg);
}

.card-liveChat .headerLiveChat .client-header {
    padding: 0 1em;
    background-color: var(--bs-primary);
}


/* BODY  */
.card-liveChat .chat {
    padding: 0 1ch;
    border-bottom-left-radius: var(--livechat-body-radius);
    border-bottom-right-radius: var(--livechat-body-radius);
    border: 2px solid var(--livechat-color-disabled);
    border-top: none;
    background-color: var(--livechat-section-bg);
}

.card-liveChat .chat>section, .card-liveChat .chat .scrollbar-typeA {
    height:calc(80vh - var(--livechat-header-height) - var(--livechat-btn));
    overflow-y:scroll;
    padding-top: 16px;
    padding-bottom: 90px;
}
.card-liveChat .chat .scrollbar-typeA {
    padding-bottom: 0px;
}


.startPanel {
    margin:auto;
    font-size: var(--livechat-font-size);
}


.startPanel .title {
    font-weight: bold;
    color: var(--livechat-header-bg);
    font-size: calc(var(--livechat-font-size) + 2px);
}

.startPanel>.description {
    color: gray;
}

.startPanel input {
    width: 100%;
    line-height: var(--livechat-line-height);
    font-size: calc(var(--livechat-font-size) + 1px);
    padding: 10px 30px 10px 10px;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    resize: none;
}

.startPanel input:focus {
    outline: none;
}

.card-liveChat .inputBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: .7;
    width: 35px;
    height: 35px;
    background: none;
    border: none;
    padding: 0;
}

.card-liveChat .inputBtn>* {
    color: var(--livechat-header-bg);
    font-size: 110%;
}

.card-liveChat>section .chat>footer {
    margin-top:10px;
}

@media (max-width: 768px) {
    .chatIntera {
        display: block !important;
        width: calc(100vw - var(--livechat-btn) - 4vw);
    }

    .card-liveChat {
        width: auto;

    }

    

    .contacts {
        margin: auto;
        margin-top: -2.5rem;
        padding-top: 3rem;
        position: relative;
        z-index: -1;
        padding-bottom: 1rem;
    }

    .card-liveChat>section .chat>footer {
        width: 94%;
    }

    .card-liveChat .chat>section, .card-liveChat .chat .scrollbar-typeA {
        max-height: 65vh;
    }
}