.card-liveChat>section {
    position:relative;
}

/* BODY: CHAT - TEXTS */
.card-liveChat>section .chat>section::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: white;
}
.card-liveChat>section .chat>section::-webkit-scrollbar{
	width: 4px;
	background-color: white;
}

.card-liveChat>section .chat>section::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: var(--livechat-header-bg);
}

.card-liveChat>section .chat .personal-msg,
.card-liveChat>section .chat .user-msg {
    border-radius: var(--livechat-body-radius);
    font-size: var(--livechat-font-size);
    padding:10px;
    margin-bottom: 1.5em;
    width: 80%;
}

.card-liveChat>section .chat .user-msg  {
    background-color: var(--livechat-header-bg);
    border-bottom-right-radius: 0;
    color: white;
    margin-right: 0;
    margin-left: auto;
}
.card-liveChat>section .chat .personal-msg {
    border: 1px solid #dee2e6;
    background-color: white;
    border-bottom-left-radius: 0;
    color: var(--bs-black);
    margin-right: auto;
    margin-left: 0;
}



/* BODY: CHAT - INPUT */
.card-liveChat>section .chat>footer {
    padding: 10px 0;
    border-top: 2px solid #dee2e6;
    position: relative;
    width: 98%;
    bottom:0;
    background-color: var(--livechat-section-bg);
}
.card-liveChat>section .chat>footer textarea {
    width: 100%;
    max-height: calc(var(--livechat-max-textarea)*3 + 15px) !important;
    line-height: var(--livechat-line-height);
    font-size: calc(var(--livechat-font-size) + 1px);
    padding: 15px 40px 15px 20px;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    min-height: 45px;
}
.card-liveChat>section .chat>footer textarea:focus {
    outline: none;
}
.card-liveChat>section .chat>footer textarea::-webkit-scrollbar{
	width: 0px;
}

/* BODY: CHAT - BUTTON */
.card-liveChat>section .chat>footer button > * {
    color: var(--livechat-header-bg);
    font-size: 110%;
}