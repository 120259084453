.MuiBox-root, .css-9qcevp-MuiTypography-root, .css-t6kcpn-MuiTypography-root {
    color:var(--bs-gray-dark) !important;
}

.MuiListItemText-root .MuiTypography-root{
    color:#fff !important;
}

.dropzone-ui .dz-ui-footer{
font-size: 0.6em !important;
}

.dropzone-ui .dz-ui-header{
    font-size: 0.6em !important;  
}

.madalconfig{
    width: 80% !important;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3 {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

h3,
h4,
h5,
h6 {
    color: var(--bs-black);
}

p{
    text-align:left;
}



.bg-primary-light{
    background: var(--bs-primary-light);
}


.accordion .accordion-button,
p,
body,
aside h5 {
    font-size: 1rem !important;
}

aside h5,
aside button p {
    color: #013057 !important;
}

.accordion-faq span.MuiTypography-root {
    background-color: #f6f6f6 !important;
    padding: 0.5rem !important;
}

.MuiContainer-root.headerNsp {
    width: 100% !important;
    max-width: 100% !important;
}

.btn-white{
    color: var(--bs-gray-dark) !important;
}

button{
    border:1px solid var(--bs-gray-light);
    padding: .6ch 2ch;
    transition: all 0.5s ease-in;
    background: var(--bs-primary);
    border-radius: .5rem;
}

.pagination button{
    margin:1rem .3rem !important;
}

.pagination button:active:focus, .pagination button:focus  {
    box-shadow: none !important;
    border:1px solid var(--bs-primary);
}

.btn,
.btn-primary {
    font-weight: 700;
    line-height: 1.25;
    color: var(--bs-white);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--bs-primary);
    border: none;
    padding: .6ch 2ch;
    font-size: 1.36rem;
    line-height: 1.3;
    font-weight: bold;
    border-radius: 36rem !important;
    transition: all 0.5s ease-in;
    text-decoration: none;
    text-transform: none;
}

.btn:hover,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: var(--bs-white);
    background: var(--bs-primary-dark) !important;
    transition: all 0.5s ease-in;
}

.btn-white{
    border:1px solid var(--bs-primary);
    padding: .6ch 2ch;
    transition: all 0.5s ease-in;
}

.btn-white:hover {
    color: var(--bs-white) !important;
    background: var(--bs-primary) !important;
    transition: all 0.5s ease-in;
}

.btn-tertiary {
    background-color: var(--bs-tertiary);
    color: var(--bs-white);

}

.btn-tertiary:hover {
    background: var(--bs-tertiary-dark);
}

.pagination .btn-primary {
    border-radius: 0.5rem !important;
    line-height:1;
}

.btn-outline-primary {
    color: var(--bs-gray-dark) !important;
    border: 3px solid var(--bs-primary) !important;
    background-color: white !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    transition: all 0.5s ease-in;
}

.btn-outline-primary:hover {
    color: white !important;
}

.btn-outline-tertiary {
    color: var(--bs-tertiary);
    border: 3px solid var(--bs-tertiary);
    background-color: white;
    transition: all .5s;
}

.btn-outline-tertiary:hover {
    color: #fff;
    background-color: var(--bs-tertiary);
    border-color: var(--bs-tertiary);
}



@media (min-width: 1200px) {
    .MuiContainer-root.headerNsp {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.montserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.skew-rotate-z45 {
    transform: rotateZ(45deg);
}

.grayscale-60 {
    filter: grayscale(60%) !important;
    -webkit-filter: grayscale(60%) !important;
    -moz-filter: grayscale(60%) !important;
}

.border-dotted {
    border-style: dotted;
}

.border-dashed {
    border-style: dashed;
}

.bg-ghostwhite {
    background-color: #f6f6f6 !important;
}

.bg-black-50 {
    background-color: rgba(0, 0, 0, 0.35) !important;
}

.subHeading {
    font-weight: normal;
    font-size: 1.3em;
}

.btn {
    font-weight: 700;
    line-height: 1.667;
    color: var(--bs-white);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: var(--bs-primary);
    border: none;
    padding: 0.5em 1em;
    font-size: 1.1rem;
    font-weight: normal;
    border-radius: 0.5rem;
    transition: all 0.15s ease-in;
    text-decoration: underline;
    text-transform: none;
}

.btn:hover {
    color: var(--bs-white);
    opacity: .75;
}

.btn-outline-primary {
    color: var(--bs-primary);
}

.btn-outline-primary {
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    background-color: white;
}

.imgNumeri {

    margin: 0 auto 2em auto;
    ;
}

.imgThumb {
    width: 6.875em;
    height: 6.875em;
    position: relative;
}

.imgLarge {
    width: 13em;
    height: auto;
    position: relative;
}

.tessera {
    border: 1px solid #f2f3f8;
    padding: 1em;
    margin: 1em auto;
    background: var(--bs-white);
}

.tessera:nth-child(odd) {
    border-top-left-radius: 2em;
    border-bottom-right-radius: 2em;
}

.tessera:nth-child(even) {
    border-top-right-radius: 2em;
    border-bottom-left-radius: 2em;
}

.btn {
    text-transform: none;
}

.pulsanti .btn {
    margin: 1em;
}

.pulsanti .btn:first-child {
    margin-left: 0em;
}

.pulsanti .btn:last-child {
    margin-right: 0em;
}



.tessera h4 {
    font-size: 1.2rem;
}

.titolosez {
    text-align: center;
    margin-top: 2rem;
}



.titolosez:before {
    content: "";
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 5px;
    margin-bottom: -0.1em;
    background: var(--bs-tertiary);
    border-top-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
}


.imgfeatures {
    margin: 2em;
}

.modal-log-user {
    background: var(--bs-primary-light);
}

.container.bg-primary-light {
    background-color: var(--bs-primary);
}

p.error {
    background: #ff000090;
    padding: .2em .1em;
    line-height: 1.2;
}

#categorie_home .container {
    padding: var(--bs-gutter-x);
}

.footer {
    background: #f6f6f6;
    border-top: 1px solid #000;
}

.rotate {
    transform: rotate(180deg);
}

.MuiFormControl-root {
    margin: 0.5em 0 !important;
}

.window-utente {
    max-width: 800px;
}

.cart .window-utente {
    background: none !important;
    max-width: 1220px;
}

.cart-panel{
 position:relative;
}

@media (max-width: 767px) {
.mobile-100{
    width:100% !important;
    display:block !important;
}

}



@media (min-width: 768px) {
    body {
        text-align: left;
    }



    .fs-md-6 {
        font-size: 1rem !important;
    }

    .top-lg-5 {
        top: 5% !important;
    }

    #numeri_home {
        background: linear-gradient(-3deg, #d8e2ff 50%, #FFFFFF 50%);
    }

    #categorie_home {
        background: linear-gradient(0deg, #f6f6f6 50%, #FFFFFF 50%);
    }

    .bg-eco {
        background: linear-gradient(90deg, #f6f6f6 50%, var(--bs-primary-light) 50%);
    }

    .container.bg-primary-light {
        background-color: var(--bs-primary-light);
    }

    .bg-lg-primary-light {
        background-color: var(--bs-primary-light) !important;
    }

    .bg-md-gray-100 {
        background-color: var(--bs-gray-100) !important;
    }

    .md-rotate {
        transform: rotate(180deg);
    }

    .titolosez {
        text-align: left;
    }

}

.center {
    margin: 0 !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bigger {
    font-size: 110%;
}

.xbigger {
    font-size: 130%;
}

.xxbigger {
    font-size: 150%;
}

.smaller {
    font-size: 90%;
}

.xsmaller {
    font-size: 80%;
}

.xxsmaller {
    font-size: 50%;
}

.py-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5em !important;
}

.bg-gradient-whiteTraffic-bottom-top {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(246, 246, 246, 1) 30%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f6f6f6", GradientType=1);
}

.svg-primary {
    filter: brightness(0) saturate(100%) invert(22%) sepia(83%) saturate(2574%) hue-rotate(221deg) brightness(81%) contrast(122%) !important;
}

@media (min-width: 992px) {
    .position-md-fixed {
        position: fixed;
    }
    .position-lg-absolute {
        position: absolute;
    }

    .h-lg-75 {
        height: 75% !important;
    }
}