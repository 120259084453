.contacts {
    border-top-right-radius: var(--livechat-body-radius);
    border-bottom-right-radius: var(--livechat-body-radius);
    border-bottom-left-radius: var(--livechat-body-radius);
    font-size: calc(var(--livechat-font-size) - 1px);
    line-height: calc(var(--livechat-line-height) + 1rem);
    height: calc(var(--livechat-body-height));
    background: var(--livechat-contacts-bg);
    z-index: calc(var(--livechat-index) - 1);
    margin-left: -2.5rem;
    padding-left: 3rem;
    padding-right: .8rem;
    overflow-y: auto;
}

.contacts>div::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.contacts>div::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.contacts>div::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.contacts>div {
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contacts li {
    padding: 10px auto;
    padding: 5px 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contacts li:hover {
    color: var(--livechat-header-bg);
    font-weight: bold;
    cursor: pointer;
}

.contacts li::before {
    list-style-type: circle;
    content: "\2022";
    color: gray;
    font-weight: bold;
    display: inline-block;
    width: 15px;
    font-size: 30px;
    margin-left: 0em;
}

.contacts li.active,
.contacts li.active::before {
    color: var(--livechat-header-bg) !important;
    font-weight: bold;
}

.contacts li.online,
.contacts li.online::before {
    color: darkgreen;
    font-weight: bold;
}

@media (max-width: 768px) {
    .contacts {
        font-size: calc(var(--livechat-font-size) - 1px);
        line-height: calc(var(--livechat-line-height) + 1rem);
        height: calc(var(--livechat-body-height) - 150px);
        background: var(--livechat-contacts-bg);
        z-index: calc(var(--livechat-index) - 1);
        margin-left: -2.5rem;
        padding-left: 3rem;
        padding-right: .8rem;
        overflow-y: auto;
    }
}