.react-page-plugin-drawer > div {
  background: linear-gradient(195deg, #10069f, #10069f) !important;
  color: white !important;
}

.react-page-plugin-drawer > div p {
  color: white !important;
}

.react-page-plugin-drawer > div li {
  color: white !important;
}

.react-page-plugin-drawer .MuiListSubheader-root {
  background: linear-gradient(195deg, #10069f, #10069f) !important;
  color: white !important;
}
.react-page-plugin-drawer .MuiInputBase-root::before {
  border-bottom: 1px solid white !important;
}

div[class*="reactPage-MuiDrawer-docked"] > div,
div[class*="reactPage-reactPage"] > div[class*="reactPage-reactPage"] {
  z-index: 9999 !important;
}

.MuiDrawer-docked .MuiIconButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiAutocomplete-popper {
  z-index: 10000 !important;
}
